import $ from "jquery"
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import money from 'v-money'             
import VueTheMask from 'vue-the-mask'
import '@/scss/global.scss'                           // ESTILIZAÇÃO GLOBAL
import vClickOutside from 'v-click-outside'           // BIBLIOTECA V-CLICK-OUTSIDE
import 'slick-carousel/slick/slick.css';              // SLICK CAROUSEL
import 'slick-carousel/slick/slick-theme.css';        // SLICK CAROUSEL
import 'slick-carousel/slick/slick.js';               // SLICK CAROUSEL
import VModal from 'vue-js-modal'                     // VUEJS MODAL
import VueQRCodeComponent from 'vue-qrcode-component' // QR CODE
import LottieAnimation from 'lottie-web-vue'          // LOTTIE ANIMATION

import VueSweetalert2 from "vue-sweetalert2"          // SWEETALERT2
import Swal from "sweetalert2/dist/sweetalert2.js"    // SWEETALERT2
import VueToastr from "vue-toastr"                    // TOASTR

import { Cropper } from 'vue-advanced-cropper'        // CROPPER 
import 'vue-advanced-cropper/dist/style.css';         // CROPPER

// Descomentar se der erro em algum lugar relacionado ao loader
// import Loading from 'vue-loading-overlay'          // LOADER
// import 'vue-loading-overlay/dist/vue-loading.css'  // LOADER
Vue.component('qr-code', VueQRCodeComponent)
Vue.config.productionTip = false
Vue.use(VueTheMask)
Vue.use(vClickOutside)
Vue.use(VModal, { dialog: true })
Vue.use(money, {precision: 4})
Vue.component(Cropper.name, Cropper);
Vue.use(LottieAnimation);
window.$ = $

// Descomentar se der erro em algum lugar relacionado ao loader
// Vue.use(Loading);
Vue.use(VueToastr, {
  // defaultPosition: "toast-top-left",
  defaultStyle: {
    "margin-top": "25px",
  }
  /* OverWrite Plugin Options if you need */
});
Vue.use(VueSweetalert2);

Vue.use(VModal);
//     color: corPrimaria,
//     loader: 'spinner',
//     width: 50,
//     height: 50,
//     backgroundColor: '#343a40',
//     opacity: 0.5,
//     zIndex: 1040
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
