<!-- https://thegood.com/wp-content/uploads/bed-bath-beyond.png -->
<template>
	<div class="checkout-page">
		<ModalPagamentoPix :dadosPix="dadosPix" :idPedido="idPedidoPix" @emitePagamento="togglePago()"></ModalPagamentoPix>
		<div class="header-checkout">
			<div class="voltar-loja">
				<router-link :to="{ name: 'MeusPedidos'}">
					<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
					<span>Voltar para a loja</span>
				</router-link>
			</div>
			<div class="logo-loja">
				<img v-if="configPathsLogos.logoIcone !== ''" :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoIcone" style="height: 80%;">
			</div>
			<div class="espaco-em-branco">

			</div>
		</div>
		<div class="time-line-checkout">
			<div class="container">                      
				<div class="row text-center justify-content-center mb-2">
					<div class="col-xl-6 col-lg-8">
						<h2 class="font-weight-bold">Finalizando sua compra</h2>
						<p class="text-muted">Siga as etapas para concluir seu pedido.</p>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
							<div class="timeline-step" :class="{'active': stepTimeLine > 0}">
								<div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="Atualize suas informações"data-original-title="2003">
									<div class="inner-circle active"></div>
									<p class="h6 text-muted mb-0 mb-lg-0">Informações do Cliente</p>
								</div>
							</div>
							<div class="timeline-step" :class="{'active': stepTimeLine > 1}">
								<div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="Confirme os dados para entrega"data-original-title="2004">
									<div class="inner-circle" :class="{'active': stepTimeLine >= 1}"></div>
									<p class="h6 text-muted mb-0 mb-lg-0">Informações de Entrega</p>
								</div>
							</div>
							<div class="timeline-step">
								<div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="Escolha a forma de pagamento"data-original-title="2005">
									<div class="inner-circle" :class="{'active': stepTimeLine >= 2}"></div>
									<p class="h6 text-muted mb-0 mb-lg-0">Método de Pagamento</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="body-checkout">
			<section v-show="parseInt(stepTimeLine) === 0" class="infos-cliente">
				<template v-if="isLoadingBody">
					<div class="container-loading-inicial">
						<div class="spinner-border" style="width: 2.3rem; height: 2.3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="col-12">
						<h3><i class="fa fa-address-card" aria-hidden="true"></i> Informações do cliente</h3>
					</div>
					<div class="container-fluid d-flex flex-column">
						<h6 class="card-subtitle mt-3 text-muted">Dados pessoais</h6>
						<div class="row mt-3">
							<div class="col-12 col-lg-7 mb-3">
								<div class="grupo-form">
									<label for="edNomePerfil">Nome completo</label>
									<div class="wrap-input-8">
										<input id="edNomePerfil" v-model="vNomeCompletoCliente" class="input" type="text" placeholder="Digite seu nome completo">
										<span class="focus-border"><i></i></span>
									</div>
								</div>
							</div>
							<div class="col-6 col-lg-5 mb-3">
								<div class="grupo-form">
									<label for="edCnpjCpfPerfil">CPF/CNPJ</label>
									<div class="wrap-input-8">
										<input id="edCnpjCpfPerfil"
											v-model="vCnpfCpfPerfil"
											v-mask="['###.###.###-##', '##.###.###/####-##']" 
											class="input" 
											type="text" 
											disabled
											@mouseover="msgCnpjCpf = true"
											@mouseleave="msgCnpjCpf = false"
										>
										<span class="focus-border"><i></i></span>
										<transition name="fade">
											<div v-if="msgCnpjCpf" class="tooltip-message">
												Para alterar o CPF/CNPJ, entre em contato conosco.
											</div>
										</transition>
									</div>
								</div>
							</div>
							<div class="col-6 col-lg-4 mb-3">
								<div class="grupo-form">
									<label for="edDtnascimentoperfil">Data de nascimento</label>
									<div class="wrap-input-8">
										<input id="edDtnascimentoperfil" v-model="vDataNascimentoPerfil" class="input" type="date" placeholder="Digite sua data de nascimento">
										<span class="focus-border"><i></i></span>
									</div>
								</div>
							</div>
							<div class="col-6 col-lg-4 mb-3">
								<div class="grupo-form">
									<label for="edTelefoneperfil">Telefone</label>
									<div class="wrap-input-8">
										<input id="edTelefoneperfil" v-mask="['(##) ####-####', '(##) #####-####']" v-model="vTelefonePerfil" class="input" type="tel" placeholder="Digite seu telefone">
										<span class="focus-border"><i></i></span>
									</div>
								</div>
							</div>
							<div class="col-6 col-lg-4 mb-3">
								<div class="grupo-form">
									<label for="edCelularperfil">Celular</label>
									<div class="wrap-input-8">
										<input id="edCelularperfil" v-mask="['(##) ####-####', '(##) #####-####']" v-model="vCelularPerfil" class="input" type="tel" placeholder="Digite seu celular">
										<span class="focus-border"><i></i></span>
									</div>
								</div>
							</div>
						</div>
						<h6 class="card-subtitle mt-3 text-muted">Informações de endereço</h6>
						<small class="form-text text-muted">O endereço para entrega será selecionado na próxima etapa.</small>
						<div class="row mt-3">
							<div class="col-3 col-lg-3 mb-3">
								<div class="grupo-form">
									<label for="edCepPerfil">CEP</label>
									<div class="wrap-input-8">
										<input id="edCepPerfil" @blur="verificaCep(vCepPerfil)" v-model="vCepPerfil" v-mask="['#####-###']" class="input" type="text" placeholder="Digite seu cep">
										<span class="focus-border"><i></i></span>
									</div>
									<div v-show="avisoCep == 1" class="aviso-cep">
										<small style="color: #a30000; font-weight: 500; font-size: 12px;">Cep inválido!</small>
									</div>
								</div>
							</div>
							<div class="col-9 col-lg-9 mb-3">
								<div class="grupo-form">
									<label for="edLogradouroPerfil">Logradouro</label>
									<div class="wrap-input-8">
										<input id="edLogradouroPerfil" v-model="vLogradouroPerfil" class="input" type="text" placeholder="Digite seu endereço">
										<span class="focus-border"><i></i></span>
									</div>
								</div>
							</div>
							<div class="col-8 col-lg-8 mb-3">
								<div class="grupo-form">
									<label for="edComplementoPerfil">Complemento</label>
									<div class="wrap-input-8">
										<input id="edComplementoPerfil" v-model="vComplementoPerfil" class="input" type="text">
										<span class="focus-border"><i></i></span>
									</div>
								</div>
							</div>
							<div class="col-4 col-lg-4 mb-3">
								<div class="grupo-form">
									<label for="edNumeroPerfil">Número</label>
									<div class="wrap-input-8">
										<input id="edNumeroPerfil" v-model="vNumeroPerfil" class="input" type="text" placeholder="Número ou S/N">
										<span class="focus-border"><i></i></span>
									</div>
								</div>
							</div>
							<div class="col-4 col-lg-4 mb-3">
								<div class="form-group">
									<label for="estado-destinatario">UF:</label>
									<select class="form-control select-perso" id="estado-destinatario" v-model="vEstadoPerfil" @change="carregaCidades()">
										<option disabled :value=null >Selecione a UF</option>
										<option v-for="estado in lstEstados" :value="estado.id">{{ estado.id }}</option>
									</select>
								</div>
							</div>
							<div class="col-4 col-lg-4 mb-3">
								<div class="form-group">
									<label for="cidadecliente">Cidade:</label>
									<select class="form-control select-perso classecidade" id="cidadecliente" v-model="vCidadePerfil">
										<option disabled :value=null >Selecione a cidade</option>
										<option v-for="cidade in lstCidades" :value="cidade.id">{{ cidade.descricao }}</option>
									</select>
								</div>
							</div>
							<div class="col-4 col-lg-4 mb-3">
								<div class="form-group">
									<label for="bairrocliente">Bairro:</label>
									<select disabled class="form-control select-perso classecidade" id="bairrocliente" v-model="vBairroPerfil">
										<option disabled :value={} >Digite seu Cep</option>
										<option v-if="vBairroPerfil" :value="vBairroPerfil">{{ vBairroPerfil.descricao }}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 mb-2">
								<div class="btns-cancelar-confirmar">
									<button class="btn-sim" @click.prevent="avancarEtapa()">
										<template v-if="isLoadingBtnAvancar">
											<div class="spinner-border" style="width: 1.3rem; height: 1.3rem; margin-inline: 23px;" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</template>
										<template v-else>
											Próximo  <i class="bi bi-arrow-right"></i>
										</template>
									</button>
								</div>
							</div>
						</div>
					</div>
				</template>
			</section>
			<section v-show="parseInt(stepTimeLine) === 1" class="ende-entrega">
				<template v-if="isLoadingBody">
					<div class="container-loading-inicial">
						<div class="spinner-border" style="width: 2.3rem; height: 2.3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</template>
				<template v-else>
					<template v-if="!isTpEntregaSelected">
						<div class="col-12">
							<h3><i class="fa fa-map-marker" aria-hidden="true"></i> Tipo de entrega</h3>
						</div>
						<h4>Selecione o tipo de entrega desejada:</h4>
						<div class="opcoes-tp-entrega">
							<div class="opcao-tp-entrega">
								<label for="op-retirar-loja">
									<input type="radio" id="op-retirar-loja" value="0" v-model="vTpEntrega">
									<p>
										<strong>Retirar meu pedido na loja</strong> - <small style="color: green;">Frete Grátis</small>
									</p>
								</label>
								<div class="opcoes-endereco">
									<p>Após aprovação da compra, verifique o horário de funcionamento da loja</p>
								</div>
							</div>
							<div class="opcao-tp-entrega">
								<label for="op-receber-casa">
									<input type="radio" id="op-receber-casa" value="1" v-model="vTpEntrega">
									<p>
										<strong>Receber meu pedido em casa</strong>
									</p>
								</label>
								<div class="opcoes-endereco">
									<p>Entregaremos seu pedido em alguns dias úteis</p>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-12 mb-2">
								<div class="btns-cancelar-confirmar">
									<button class="btn-sim" @click.prevent="avancarEtapaTipoEntrega()">
										<template v-if="isLoadingBtnAvancar">
											<div class="spinner-border" style="width: 1.3rem; height: 1.3rem; margin-inline: 23px;" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</template>
										<template v-else>
											Próximo  <i class="bi bi-arrow-right"></i>
										</template>
									</button>
								</div>
							</div>
						</div>
					</template>
					<!-- Endereço para retirar o produto na loja -->
					<template v-else-if="isTpEntregaSelected && vTpEntrega == 0">
						<div class="col-12">
							<h3><i class="fa fa-map-marker" aria-hidden="true"></i> Informações para retirada do pedido</h3>
						</div>
						<h4>Leia com atenção:</h4>
						<div class="container-infos-retirada">
							<div class="card-instrucoes-retirada">
								<div class="card-instrucoes-header">
									<h3>INSTRUÇÕES:</h3>
								</div>
								<div class="card-instrucoes-body">
									<p>Ao chegar na loja, dirija-se ao <strong>setor de Atendimento</strong> portando documento original com foto para retirar o pedido.</p>
									<p>Você tem 15 dias corridos, após liberação da retirada, para buscar o produto. Caso o pedido não seja retirado nesse prazo, 
										ele será cancelado automaticamente e um vale ficará disponível para uma nova compra no site.</p>
									<span><strong>Retirada por terceiro:</strong></span>
									<p style="margin: 0;">Caso o titular da compra não possa comparecer, o documento original com foto de ambos (comprador e representante) deverá ser apresentado no
										momento da retirada do pedido.</p>
								</div>
							</div>
							<div class="container-endereco-horarios">
								<div class="endereco-retirada">
									<div class="tit-end-retirada">
										<i class="fa fa-shopping-bag" aria-hidden="true"></i><span>Endereço de retirada</span>
									</div>
									<div class="infos-end-retirada">
										<span><strong>{{ filial.fantasia }}</strong></span>
										<p>{{ filial.endereco.logradouro }}, {{ filial.endereco.numero }}</p>
										<p>{{ filial.endereco.descbairro }} - CEP: {{ formataCEP(filial.endereco.cep) }}</p>
										<p>{{ filial.endereco.desccidade }} - {{ filial.endereco.iduf }}</p>
									</div>
								</div>
								<div class="horario-retirada">
									<div class="tit-hor-retirada">
										<i class="fa fa-clock-o" aria-hidden="true"></i><span>Horário para retirada</span>
									</div>
									<div class="infos-hor-retirada">
										<p><strong>Segunda a Sexta:</strong> 09:00 - 18:00</p>
										<p><strong>Sábado:</strong> 09:00 - 14:00</p>
										<p><strong>Domingo:</strong> Fechado</p>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 mb-2">
								<div class="btns-cancelar-confirmar">
									<button class="btn-sim" @click.prevent="avancarEtapa()">
										<template v-if="isLoadingBtnAvancar">
											<div class="spinner-border" style="width: 1.3rem; height: 1.3rem; margin-inline: 23px;" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</template>
										<template v-else>
											Próximo  <i class="bi bi-arrow-right"></i>
										</template>
									</button>
								</div>
							</div>
						</div>
					</template>
					<template v-else-if="isTpEntregaSelected && vTpEntrega == 1">
						<div class="col-12">
							<h3><i class="fa fa-map-marker" aria-hidden="true"></i> Endereço de entrega</h3>
						</div>
						<h4>Selecione um endereço para a entrega:</h4>
						<div class="enderecos-entrega">
							<div class="endereco-entrega">
								<label for="endereco-principal">
									<input type="radio" id="endereco-principal" :value="0" v-model="vEnderecoEntrega">
									<p><strong>{{ endEntregaPrincipal.nomedestinatario }}</strong> - 
										{{ endEntregaPrincipal.logradouro+ ', ' + endEntregaPrincipal.numero + ' - ' + endEntregaPrincipal.complemento + ' - ' + endEntregaPrincipal.bairro + ', ' + endEntregaPrincipal.cidade.descricao + ' - ' + endEntregaPrincipal.estado.id}}</p>
								</label>
								<div class="opcoes-endereco">
									<p>Endereço padrão</p>
								</div>
							</div>
							<div v-for="endereco in lstEnderecos" :key="endereco.id" class="endereco-entrega">
								<label :for="'endereco-' + endereco.id">
									<input type="radio" :id="'endereco-' + endereco.id" :value="endereco.id" v-model="vEnderecoEntrega">
									<p><strong>{{ endereco.nomedestinatario }}</strong> - 
										{{ endereco.logradouro+ ', ' + endereco.numero + ' - ' + endereco.complemento + ' - ' + endereco.bairro.descricao + ', ' + endereco.cidade.descricao + ' - ' + endereco.estado.id}}</p>
								</label>
								<div class="opcoes-endereco">
									<button @click.prevent="exibeModalNovoEndereco(endereco)">Editar</button>
									<button @click.prevent="confirmaAlteracaoEndereco(endereco.id, 3)">Excluir</button>
								</div>
							</div>
						</div>
						<div class="novo-endereco">
							<button @click.prevent="exibeModalNovoEndereco()"><i class="fa fa-plus" aria-hidden="true" style="color: #c1c1c1;"></i> Adicionar novo endereço</button>
						</div>
						<div class="row">
							<div class="col-12 mb-2">
								<div class="btns-cancelar-confirmar">
									<button class="btn-sim" @click.prevent="avancarEtapa()">
										<template v-if="isLoadingBtnAvancar">
											<div class="spinner-border" style="width: 1.3rem; height: 1.3rem; margin-inline: 23px;" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</template>
										<template v-else>
											Próximo  <i class="bi bi-arrow-right"></i>
										</template>
									</button>
								</div>
							</div>
						</div>
					</template>
				</template>
			</section>
			<section v-show="parseInt(stepTimeLine) === 2" class="metodo-pagamento">
				<template v-if="isLoadingBody">
					<div class="container-loading-inicial">
						<div class="spinner-border" style="width: 2.3rem; height: 2.3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="col-12">
						<h3><i class="fa fa-credit-card-alt" aria-hidden="true" style="font-size: 18px;"></i> Método de pagamento</h3>
					</div>
					<h4>Selecione a forma de pagamento do seu pedido:</h4>
					<div class="enderecos-entrega">
						<div class="endereco-entrega">
							<label for="endereco-principal">
								<input type="radio" id="endereco-principal" value="0" v-model="vPlanoPagamento">
								<p><strong>Pix</strong> - O código Pix gerado para o pagamento é válido por 30 minutos após a finalização do pedido.</p>
							</label>
							<div class="opcoes-endereco">
								<p>Recomendado</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 mb-2 mt-4">
							<div class="btns-cancelar-confirmar">
								<button class="btn-sim" @click.prevent="avancarEtapa()">
									<template v-if="isLoadingBtnAvancar">
										<div class="spinner-border" style="width: 1.3rem; height: 1.3rem; margin-inline: 23px;" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</template>
									<template v-else>
										Finalizar pedido  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
									</template>
								</button>
							</div>
						</div>
					</div>
				</template>
			</section>
			<section class="sumario-checkout">
				<div class="container-sumario">
					<div class="header-sumario">
						<h3>Resumo do pedido</h3>
					</div>
					<div class="divisoria-itens-pedido" style="margin-bottom: 10px;"></div>
					<div class="body-sumario">
						<div class="precos-pedido">
							<p>Subtotal:</p><span>R$ {{ formataReal(vlSubtotal) }}</span>
						</div>
						<div class="precos-pedido">
							<p>Frete:</p><span>R$ {{ formataReal(vlFrete) }}</span>
						</div>
						<div class="precos-pedido">
							<p>Descontos:</p><span>R$ {{ formataReal(vlDescontos) }}</span>
						</div>
						<div class="divisoria-itens-pedido" style="margin-top: 5px;"></div>
						<div class="precos-pedido mt-2">
							<p><strong>Total pedido:</strong></p><span><strong> R$ {{ formataReal(vlTotalPedido) }}</strong></span>
						</div>
					</div>
				</div>
			</section>
		</div>
		<template>
			<modal name="modal-novo-endereco" @before-enter="igualaCamposModalEnderecoNovo" @before-close="limpaCamposModalEnderecoNovo()" height="auto" width="800" styles="background-color: transparent;">
				<div class="modal-novo-endereco">
					<div class="header-novo-endereco">
						<h3>{{ titModalEndereco }} endereço de envio</h3>
					</div>
					<div class="body-novo-endereco">
						<div class="container-fluid">
							<div class="row">
								<div class="col-sm-12 col-md-6 col-lg-6 mb-3">
									<div class="grupo-form">
										<label for="nome-destinatario">Nome do destinatário:</label>
										<div class="wrap-input-8">
											<input id="nome-destinatario" v-model="vNomeEnderecoNovo" class="input" type="text" placeholder="Digite o destinatário">
											<span class="focus-border"><i></i></span>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-3 col-lg-3 mb-3">
									<div class="grupo-form">
										<label for="Contato-destinatario">Contato:</label>
										<div class="wrap-input-8">
											<input id="Contato-destinatario" v-model="vContatoEnderecoNovo" class="input" type="text" placeholder="Digite telefone">
											<span class="focus-border"><i></i></span>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-3 col-lg-3 mb-3">
									<div class="grupo-form">
										<label for="cep-destinatario">CEP:</label>
										<div class="wrap-input-8">
											<input id="cep-destinatario" v-model="vCepEnderecoNovo" @blur="verificaCep()" v-mask="['#####-###']" class="input" type="text" placeholder="Digite seu endereço">
											<span class="focus-border"><i></i></span>
										</div>
										<div v-show="avisoCep == 1" class="aviso-cep">
											<small style="color: #a30000; font-weight: 500; font-size: 12px;">Cep inválido!</small>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-8 col-lg-8 mb-3">
									<div class="grupo-form">
										<label for="logradouro-destinatario">Logradouro:</label>
										<div class="wrap-input-8">
											<input id="logradouro-destinatario" v-model="vLogradouroEnderecoNovo" class="input" type="text" placeholder="Digite endereço">
											<span class="focus-border"><i></i></span>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4 mb-3">
									<div class="grupo-form">
										<label for="numero-destinatario">Número:</label>
										<div class="wrap-input-8">
											<input id="numero-destinatario" v-model="vNumeroEnderecoNovo" class="input" type="text" placeholder="Número ou S/N">
											<span class="focus-border"><i></i></span>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12 col-lg-12 mb-3">
									<div class="grupo-form">
										<label for="complemento-destinatario">Complemento:</label>
										<div class="wrap-input-8">
											<input id="complemento-destinatario" v-model="vComplementEnderecoNovo" class="input" type="text" placeholder="">
											<span class="focus-border"><i></i></span>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4 mb-3">
									<div class="form-group">
										<label for="estado-destinatario">UF:</label>
										<select class="form-control select-perso" id="estado-destinatario" v-model="vEstadoEnderecoNovo" @change="carregaCidades()">
											<option disabled :value={}> Selecione a UF</option>
											<option v-for="estado in lstEstados" :value="estado.id">{{ estado.id }}</option>
										</select>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4 mb-3">
									<div class="form-group">
										<label for="cidade-destinatario">Cidade:</label>
										<select class="form-control select-perso" id="cidade-destinatario" v-model="vCidadeEnderecoNovo">
											<option disabled :value={} >Selecione a cidade</option>
											<option v-for="cidade in lstCidades" :value="cidade.id">{{ cidade.descricao }}</option>
										</select>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4 mb-3">
									<div class="form-group">
										<label for="bairro-destinatario">Bairro:</label>
										<select disabled class="form-control select-perso" id="bairro-destinatario" v-model="vBairroEnderecoNovo">
											<option disabled :value={} >Digite seu Cep</option>
											<option v-if="vBairroEnderecoNovo" :value="vBairroEnderecoNovo">{{ vBairroEnderecoNovo.descricao }}</option>
										</select>
									</div>
								</div>
								<div class="col-sm-12 mt-1">
									<div class="btns-cancelar-confirmar">
										<button class="btn-nao" @click.prevent="$modal.hide('modal-novo-endereco')">Cancelar</button>
										<button class="btn-sim" @click.prevent="confirmaAlteracaoEndereco()">Salvar <i class="fa fa-floppy-o" aria-hidden="true"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</modal>
		</template>
		<template>
			<modal name="modal-confirma-alteracao-endereco" width="400" height="auto" styles="border-radius: 15px;">
				<div class="modal-excluir-endereco">
					<h5 v-if="opcrudEndereco == 1">Deseja realmente incluir este endereço?</h5>
					<h5 v-else-if="opcrudEndereco == 2">Deseja realmente alterar este endereço?</h5>
					<h5 v-else>Deseja realmente excluir este endereço?</h5>
					<div class="btns-cancelar-confirmar">
						<button class="btn-nao" @click.prevent="$modal.hide('modal-confirma-alteracao-endereco')">Cancelar</button>
						<button v-if="opcrudEndereco != 3" class="btn-sim" @click.prevent="registroEndereco()">Confirmar</button>
						<button v-else class="btn-sim" @click.prevent="registroEndereco(3)">Excluir</button>
					</div>
				</div>
			</modal>
		</template>
    </div>
</template>

<script>
// @ is an alias to /src
import ModalPagamentoPix from '@/components/ModalPagamentoPix/ModalPagamentoPix.vue';
import utils from '@/utilitarios/utils';
import LottieAnimation from 'lottie-web-vue'

export default {
	name: 'Checkout',
	components: {
		ModalPagamentoPix,
		LottieAnimation
	},
	data: function() {
		return {
			isLoadingBody			: true,
			isLoadingBtnAvancar		: false,
			isTpEntregaSelected		: false, 

			connSocketIo			: null,

			stepTimeLine			: 0, 	// 0 - Informações do Cliente, 1 - Informações de Entrega, 2 - Método de Pagamento 
			opcrudEndereco			: null, // Operação CRUD endereço
			vTpEntrega				: 0,	// Tipo de entrega selecionado
			vEnderecoEntrega		: "0", // Endereço de entrega selecionado
			avisoCep				: 0,	// Aviso CEP inválido
			
			// Variáveis resumo pedido
			vlSubtotal				: 0,
			vlFrete					: 0,
			vlDescontos				: 0,
			vlTotalPedido			: 0,

			// Variáveis informações cliente
			vNomeCompletoCliente	: '',
			vCnpfCpfPerfil			: '',
			msgCnpjCpf				: false,
			vDataNascimentoPerfil	: null,
			vTelefonePerfil			: '',
			vCelularPerfil			: '',
			vLogradouroPerfil		: '',
			vCepPerfil				: '',
			vNumeroPerfil			: '',
			vComplementoPerfil		: '',
			vEstadoPerfil			: null,
			vCidadePerfil			: null,
			vBairroPerfil			: {},

			// Variáveis modal endereço entrega
			vIdEnderecoAlterar		: null,
			titModalEndereco		: 'Alterar',
			vNomeEnderecoNovo		: '',
			vContatoEnderecoNovo	: '',
			vLogradouroEnderecoNovo	: '',
			vNumeroEnderecoNovo		: '',
			vComplementEnderecoNovo	: '',
			vCepEnderecoNovo		: '',
			vEstadoEnderecoNovo		: null,
			vCidadeEnderecoNovo		: null,
			vBairroEnderecoNovo		: {},

			endEntregaPrincipal		: {
				// Inicializando objetos para evitar erros de undefined
				cidade		: {}, 
				estado		: {},
			},
			lstEnderecos			: [],
			lstEstados				: [],
			lstCidades				: [],
			lstBairros				: [],

			// Variaveis planos pagamento
			vPlanoPagamento			: 0,
			dadosPix				: {},
			idPedidoPix				: '',
        };
    },
    methods: {
		toCapitalized(value){
			return utils.capitalizeText(value);
		},
		dataPorExtenso(data){
			return utils.dataPorExtenso(data);
		},
		formataReal(valor, prefixo = ''){
			return utils.formataReal(valor, prefixo);
		},
		formataCEP(cep){
			if (cep){
				return cep.substring(0, 5) + '-' + cep.substring(5, 8);
			}
		},
		removerAcentos(texto = '') {
			return utils.removeAcentos(texto).toLowerCase();
		},
		exibeModalNovoEndereco(endereco = null){
			if (endereco != null){
				this.titModalEndereco = 'Alterar';
				this.opcrudEndereco = 2;
				this.vIdEnderecoAlterar = endereco.id;
				this.igualaCamposModalEnderecoNovo(endereco);
			} else {
				this.titModalEndereco = 'Adicionar novo';
				this.opcrudEndereco = 1;
			}
			this.$modal.show('modal-novo-endereco');
		},
		verificaCep(){
			let vself = this;
			let cepinf = vself.stepTimeLine == 0 ? vself.vCepPerfil : vself.vCepEnderecoNovo;
			if (utils.IsCEP(cepinf, '')){
					if ( cepinf != ''){
						vself.isLoadingBtnAvancar = true;
						let parametros = [
							{parametro: 'param1'		, valor: 'consultarcep'},
							{parametro: 'idclientesac'	, valor: vself.clienteSac.id},
							{parametro: 'cep'			, valor: cepinf}
						];
						let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/buscarcep.php');
						retorno.then(function (response) {
							vself.isLoadingBtnAvancar = false;
							if (response.data.idmunicipio) {
								if (vself.stepTimeLine == 0){
									vself.vLogradouroPerfil = response.data.logradouro;
									vself.vComplementoPerfil = response.data.complemento;
									vself.vNumeroPerfil = '';
									vself.carregaEstados(response.data.iduf);
									setTimeout(() => {
										vself.carregaCidades(response.data.idmunicipio);
									}, 500);
									vself.vBairroPerfil = {id: response.data.idbairro, descricao: response.data.descbairro};
								} else if (vself.stepTimeLine == 1){
									vself.vLogradouroEnderecoNovo = response.data.logradouro;
									vself.vComplementEnderecoNovo = response.data.complemento;
									vself.vNumeroEnderecoNovo = '';
									vself.carregaEstados(response.data.iduf);
									setTimeout(() => {
										vself.carregaCidades(response.data.idmunicipio);
									}, 500);
									vself.vBairroEnderecoNovo = {id: response.data.idbairro, descricao: response.data.descbairro};
								}
						}else{
							vself.emiteAvisoCep();
						}					

					}).catch(function (response) {
						vself.isLoadingBtnAvancar = false;
						console.log(response.message);
					});
				}
			}             
		},
		emiteAvisoCep(){
			this.avisoCep = 1;
			setTimeout(() => {
				this.avisoCep = 0;
			}, 3000);
		},
		carregaEstados(estado = null){
			let vself = this;
			vself.lstEstados = [];
			vself.vEstadoPerfil = null;
			let parametros = [
                {parametro: 'op',           	valor: 'listagemestados'},
                {parametro: 'idclientesac',     valor: vself.clienteSac.id},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
            retorno.then(function (response) {
				vself.lstEstados = response.data
				if (estado){
					if (vself.stepTimeLine == 0){
						vself.vEstadoPerfil = estado;
					} else if (vself.stepTimeLine == 1){
						vself.vEstadoEnderecoNovo = estado;
					}
				}
				// console.log(vself.lstEstados);
            }).catch(function (response) {
                console.log(response.message);
            });
		},
		carregaCidades(cidade = null){
			let estado = this.stepTimeLine == 0 ? this.vEstadoPerfil : this.vEstadoEnderecoNovo;
			let vself = this;
			vself.lstCidades = [];
			let parametros = [
				{parametro: 'op',           	valor: 'listagemcidade'},
				{parametro: 'idclientesac',     valor: 	vself.clienteSac.id},
				{parametro: 'idestado',       	valor:	estado},
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
			retorno.then(function (response){
				vself.lstCidades = response.data;
				if (cidade){
					if(vself.stepTimeLine == 0) {
						vself.vCidadePerfil = cidade;
					}else if (vself.stepTimeLine == 1){ 
						vself.vCidadeEnderecoNovo = cidade;
					}
				} else {
					if(vself.stepTimeLine == 0) {
						vself.vCidadePerfil = null;
					}else if (vself.stepTimeLine == 1){ 
						vself.vCidadeEnderecoNovo = null;
					}
				}
			}).catch(function (response) {
				console.log(response.message);
			});	
		},
		carregaDadosCliente(){
			let vself = this;
			vself.isLoadingBody = true;
            let parametros = [
                {parametro: 'op',           	valor: 'carregadadoscliente'},
                {parametro: 'idcliente',    	valor: this.$store.state.usuarioLogado.id},
                {parametro: 'idclientesac',     valor: this.$store.state.clienteSac.id}
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcheckout.php');
            retorno.then(function (response) {
				// console.log(response.data);
				console.log(response.data.estado.id);
				if(parseInt(response.data.qtdusu) > 0){
					vself.vNomeCompletoCliente		= response.data.nome;
					vself.vCnpfCpfPerfil			= response.data.cnpjcpf;
					vself.vDataNascimentoPerfil		= response.data.dtnascimento;
					vself.vTelefonePerfil			= response.data.fone1;
					vself.vCelularPerfil			= response.data.fone2;
					vself.vLogradouroPerfil			= response.data.logradouro;
					vself.vCepPerfil				= response.data.cep;
					vself.vNumeroPerfil				= response.data.numero;
					vself.vComplementoPerfil		= response.data.complemento;
					vself.vBairroPerfil				= response.data.bairro 		? response.data.bairro : {};
					vself.vEstadoPerfil				= response.data.estado.id 	? response.data.estado.id : null;
					
					vself.isLoadingBody 			= false;
					vself.carregaCidades(response.data.cidade.id ? response.data.cidade.id : null);
				}
				
            }).catch(function (response) {
                console.log(response.message);
            });
		},
		atualizaDadosCliente(){
			let vself = this;

			let registroCrud = {
				idcliente		: vself.usuarioLogado.id,
				nome			: vself.vNomeCompletoCliente,
				dtnascimento	: vself.vDataNascimentoPerfil,
				fone1			: vself.vTelefonePerfil,
				fone2			: vself.vCelularPerfil,
				cep				: vself.vCepPerfil,
				logradouro		: vself.vLogradouroPerfil,
				numero			: vself.vNumeroPerfil,
				complemento		: vself.vComplementoPerfil,
				bairro			: vself.vBairroPerfil.id,
				estado			: vself.vEstadoPerfil,
				cidade			: vself.vCidadePerfil,
			}
			let parametros = [
				{parametro: 'idclientesac',     valor: vself.clienteSac.id},
				{parametro: 'op',           	valor: 'rotinacrudcliente'},
				{parametro: 'registro',     	valor: JSON.stringify(registroCrud)},
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcheckout.php');
			retorno.then(function (response) {
				vself.isLoadingBtnAvancar = false;
				if(response.data.inf == 1){
					vself.$toastr.s('Tudo certo! Agora selecione seu endereço para entrega.', 'Eba!');
					vself.stepTimeLine = 1;
				} else {
					vself.$toastr.e('Ops! Algo deu errado. Tente novamente.', 'Erro!');
				}
			}).catch(function (response) {
				console.log(response.message);
				vself.$toastr.e('Ops! Algo deu errado. Tente novamente.', 'Erro!');
				vself.isLoadingBtnAvancar = false;
			});
		},
		carregaEnderecosEntrega(){
			let vself = this;
			vself.isLoadingBody = true;
			let parametros = [
				{parametro: 'op',           	valor: 'listagemenderecosentrega'},
				{parametro: 'idclientesac',     valor: vself.clienteSac.id},
				{parametro: 'idcliente',       	valor: vself.usuarioLogado.id},
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcheckout.php');
			retorno.then(function (response){
				vself.endEntregaPrincipal 	= response.data.endprincipal;
				vself.endEntregaPrincipal.id = 0;
				vself.vEnderecoEntrega		= vself.endEntregaPrincipal.id;
				if(response.data.qtdenderecos > 0){
					vself.lstEnderecos = response.data.enderecos;
				} else {
					vself.lstEnderecos = [];
				}
				vself.isLoadingBody = false;
			}).catch(function (response) {
				console.log(response.message);
			});	
		},
		igualaCamposModalEnderecoNovo(endereco = null){
			if(endereco != null){
				this.vIdEnderecoAlterar			= endereco.id;
				this.vNomeEnderecoNovo			= endereco.nomedestinatario;
				this.vContatoEnderecoNovo		= endereco.contato;
				this.vLogradouroEnderecoNovo	= endereco.logradouro;
				this.vNumeroEnderecoNovo		= endereco.numero;
				this.vComplementEnderecoNovo	= endereco.complemento;
				this.vCepEnderecoNovo			= endereco.cep;
				
				this.vEstadoEnderecoNovo		= endereco.estado.id;
				this.carregaCidades(endereco.cidade.id);

				this.vBairroEnderecoNovo		= endereco.bairro;
			} else {
				this.limpaCamposModalEnderecoNovo();
			}
		},
		limpaCamposModalEnderecoNovo(){
			this.vIdEnderecoAlterar			= null;
			this.opcrudEndereco 			= null;
			this.vNomeEnderecoNovo			= '';
			this.vContatoEnderecoNovo		= '';
			this.vLogradouroEnderecoNovo	= '';
			this.vNumeroEnderecoNovo		= '';
			this.vComplementEnderecoNovo	= '';
			this.vCepEnderecoNovo			= '';
			this.vEstadoEnderecoNovo		= '';
			this.vCidadeEnderecoNovo		= null;
			this.vBairroEnderecoNovo		= null;
			this.titModalEndereco			= 'Adicionar novo';
			this.avisoCep					= 0;
		},
		confirmaAlteracaoEndereco(id = null, opcrud = null){
			if(id){
				this.vIdEnderecoAlterar = id;
			}
			if(opcrud){
				this.opcrudEndereco = opcrud;
			}
			this.$modal.show('modal-confirma-alteracao-endereco');
		},
		registroEndereco(){
			let vself = this;
            let registroCrud = {
                id              	: vself.vIdEnderecoAlterar,
                idcliente       	: vself.usuarioLogado.id,
				nomedestinatario	: vself.vNomeEnderecoNovo,
				contato				: vself.vContatoEnderecoNovo,
				cep					: vself.vCepEnderecoNovo,
				logradouro			: vself.vLogradouroEnderecoNovo,
				numero				: vself.vNumeroEnderecoNovo,
				complemento			: vself.vComplementEnderecoNovo,
				bairro				: vself.vBairroEnderecoNovo.id,
				estado				: vself.vEstadoEnderecoNovo,
				cidade				: vself.vCidadeEnderecoNovo,
            }
            let parametros = [
                {parametro: 'op',           	valor: 'rotinacrudenderecos'},
                {parametro: 'opcrud',    		valor: vself.opcrudEndereco},
                {parametro: 'registro',     	valor: JSON.stringify(registroCrud)},
                {parametro: 'idclientesac',     valor: vself.$store.state.clienteSac.id}
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcheckout.php');
            retorno.then(function (response) {
                if(isNaN(response.data)){
					// console.log(response.data);
					vself.$modal.hide('modal-confirma-alteracao-endereco');
				} else {
					vself.carregaEnderecosEntrega();
					vself.$modal.hide('modal-novo-endereco');
					vself.$modal.hide('modal-confirma-alteracao-endereco');
				}
            }).catch(function (response) {
                console.log(response.message);
				vself.$modal.hide('modal-novo-endereco');
            });
		},
		avancarEtapa(){
			this.isLoadingBtnAvancar = true;

			if (this.stepTimeLine == 0){
				this.atualizaDadosCliente();
			} else if (this.stepTimeLine == 1){
				// Verificações necessárias para avançar para a próxima etapa
				setTimeout(() => {
					this.stepTimeLine = 2;
					this.$toastr.s('Agora selecione a forma de pagamento.', 'Quase pronto!');
					this.isLoadingBtnAvancar = false;
				}, 500);
			} else if (this.stepTimeLine == 2){
				// this.isLoadingBtnAvancar = true;
				this.registraNovoPedido();
				// Verificações necessárias para concluir o pedido
			}
		},
		avancarEtapaTipoEntrega(){
			this.isTpEntregaSelected = true;
			this.stepTimeLine = 1;
		},
		registraNovoPedido(){
			let registrocrud = {
				'idfilial'          : this.filial.id,
				'idcliente'			: this.usuarioLogado.id,
				'idvendedor'		: 1, // Vendedor padrão
				'tipo'				: 0, // Pedido de venda
				'dtemissao'			: new Date().toISOString().slice(0, 10), // Data atual
				'idplano'			: 1,
				'totfrete'		    : 0, // colocar valor do frete aqui
				'stpedido'			: 0,
				'dtfatura'			: '',
				'entregaexterna'	: 1,
				'origem'			: 6,
				'idenderecoentrega'	: parseInt(this.vTpEntrega) === 0 ? 0 : this.vEnderecoEntrega.id,
			};

			let vself = this;
			vself.isLoadingBtnAvancar = true;
			let parametros = [
				{parametro: 'idclientesac',     valor: vself.clienteSac.id},
				{parametro: 'idcliente',     	valor: vself.usuarioLogado.id},
				{parametro: 'op',           	valor: 'registranovopedido'},
				{parametro: 'registro',    		valor: JSON.stringify(registrocrud)},
				{parametro: 'idfilial',    		valor: vself.filial.id},
				{parametro: 'idtabelaprecos',	valor: vself.filial.idtabelaprecos},
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcheckout.php');
			retorno.then(function (response){
				console.log(response.data);
				if (response.data.qtderros == 0){
					let pedido = {
						id		: response.data.idpedido,
						valor	: response.data.valor
					}
					vself.geraCobrancaPix(pedido);
				} else {
					vself.isLoadingBtnAvancar = false;
					console.log(response.data.erro);
				}
			}).catch(function (response) {
				vself.isLoadingBtnAvancar = false;
				console.log(response.message);
			});
		},
		geraCobrancaPix(pedido){
			let vself = this;
			let parametros = [
				{parametro: 'op'				, valor: 'geracobrancapix'},
				{parametro: 'idclientesac'		, valor: vself.clienteSac.id},
				{parametro: 'idfilial'			, valor: vself.filial.id},
				{parametro: 'idcliente'			, valor: vself.usuarioLogado.id},
				{parametro: 'valor'				, valor: pedido.valor},
				{parametro: 'referencia'		, valor: pedido.id},
				{parametro: 'tpforma'			, valor: 0}, // 0 - Pix
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
			retorno.then(function (response){
				if(typeof response.data.inf === 'number'){
					vself.dadosPix = response.data.cobranca;
					vself.idPedidoPix = response.data.idpedido;
					vself.$modal.show('modal-pagamento-pix');
				} else {
					alert('Erro ao gerar cobrança Pix');
				} 
				vself.isLoadingBtnAvancar = false;
			}).catch(function (response) {
				console.log(response.message);
				vself.isLoadingBtnAvancar = false;
			});
		},
		calculaTotalPedido(){
			let vself = this;
			let parametros = [
				{parametro: 'op',           	valor: 'calculatotalpedido'},
				{parametro: 'idclientesac',     valor: vself.clienteSac.id},
				{parametro: 'idcliente',       	valor: vself.usuarioLogado.id},
				{parametro: 'idtabelaprecos',	valor: vself.filial.idtabelaprecos},
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcheckout.php');
			retorno.then(function (response){
				vself.vlSubtotal		= response.data.subtotal;
				vself.vlFrete			= response.data.frete;
				vself.vlDescontos		= response.data.descontos;
				vself.vlTotalPedido		= response.data.total;
			}).catch(function (response) {
				console.log(response.message);
			});
		},
    },
	computed: {
		clienteSac(){
            return this.$store.state.clienteSac;
        },
        filial(){
            return this.$store.state.filial;
        },
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        configPathsLogos() {
            return this.$store.state.configPathsLogos;
        },
        configPaths() {
            return this.$store.state.configPaths;
        },
        infosGeraisProdutos(){
            return this.$store.state.infosGeraisProdutos;
        },
		destaquesProdutos(){
			return this.$store.state.filial.destaquesProdutos;
		},
		produtosAleatorios(){
			return this.$store.state.produtosAleatorios;
		},
	},
	created: function() {
		
	},
	beforeDestroy: function() {
  	},
	mounted: function() {
		if (window.localStorage.getItem('usuarioLogado') != null){      
			let payload = (JSON.parse(window.localStorage.getItem('usuarioLogado')))   
			this.$store.commit('MUDAR_USUARIO_LOGADO', payload);
		}
		this.carregaDadosCliente();
		this.carregaEstados();


		// TIRAR ISSO DAQUI QUANDO TERMINAR DE FAZER A ROTINA DE PEDIDOS
		this.carregaEnderecosEntrega(); // TIRAR ISSO DAQUI QUANDO TERMINAR DE FAZER A ROTINA DE PEDIDOS
		// TIRAR ISSO DAQUI QUANDO TERMINAR DE FAZER A ROTINA DE PEDIDOS

		this.calculaTotalPedido();
		// setInterval(() => {
		// 	console.log(this.vEstadoPerfil);
		// }, 3000);
	},
	watch: {
		stepTimeLine: function(){
			if(this.stepTimeLine == 0){
				this.carregaDadosCliente();
			} else if(this.stepTimeLine == 1){
				this.isTpEntregaSelected = false;
				this.carregaEnderecosEntrega();
			} else if(this.stepTimeLine == 2){
				console.log(this.vEnderecoEntrega)
				// this.carregaMetodosPagamento();
			}
		},
	}
}
</script>
<style scoped lang="scss">

.divisoria-itens-pedido{
	background-color: #c0c0c0;
	width: 100%;
	height: 1px;
}


.container-loading-inicial{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 45vh;
}

.checkout-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.066)
}

.header-checkout{
	height: 70px;
	width: 100vw;
	background-color: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > div{
		width: 100%;
		height: 100%;
	}
}

.voltar-loja{
	display: flex;
	align-items: center;
	padding-left: 20px;
	font-size: 18px;
	& > a{
		color: var(--cor-primaria);
		text-decoration: none;
		& > span {
			margin-left: 5px;
		}
	}
}

.logo-loja{	
	display: flex;
	justify-content: center;
	align-items: center;
}


.body-checkout{
	display: flex;
	border-radius: 15px;
	overflow: hidden;
	width: 1100px;
	margin-inline: auto;
	min-height: 500px;
	margin-bottom: 50px;
	padding-top: 20px;
}

.infos-cliente{
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	width: 100%;
	height: fit-content;
	padding: 18px;
	&>div {
		display: flex;
		&>h3{
			font-size: 23px;
			font-weight: 500;
			color: var(--cor-primaria);
		}
	}
	&>h4 {
		padding-left: 30px;
		margin-top: 10px;
		margin-bottom: 0;
		width: 100%;
		text-align: left;
		font-size: 18px;
		font-weight: 500;
		color: #646464;
	}
}
.ende-entrega{
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	width: 100%;
	height: fit-content;
	padding: 18px;
	&>div {
		display: flex;
		&>h3{
			font-size: 23px;
			font-weight: 500;
			color: var(--cor-primaria);
		}
	}
	&>h4 {
		padding-left: 30px;
		margin-top: 10px;
		margin-bottom: 0;
		width: 100%;
		text-align: left;
		font-size: 18px;
		font-weight: 500;
		color: #646464;
	}
}

.opcoes-tp-entrega{
	display: flex;
	flex-direction: column;
	&>.opcao-tp-entrega{
		height: fit-content;
		margin-top: 10px;

		&>label{
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			border: 1px solid #c0c0c0;
			display: flex;
			align-items: center;
			height: 50px;
			background-color: #fff;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
			cursor: pointer;
			margin-bottom: 0;

			&:hover{
				background-color: #f0f0f0c4;
			}

			&>input{
				margin-inline: 10px;
			}

			&>p{
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 0;
				text-align: left;
			}
		}
	}
}

.enderecos-entrega {
	display: flex;
	flex-direction: column;
	&>.endereco-entrega{
		height: fit-content;
		margin-top: 10px;

		&>label{
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			border: 1px solid #c0c0c0;
			display: flex;
			align-items: center;
			height: 50px;
			background-color: #fff;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
			cursor: pointer;
			margin-bottom: 0;

			&:hover{
				background-color: #f0f0f0c4;
			}

			&>input{
				margin-inline: 10px;
			}

			&>p{
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 0;
				text-align: left;
			}
		}
	}
}
.opcoes-endereco{
	display: flex;
	border: 1px solid #c0c0c0;
	border-top: none;
	background-color: #f0f0f0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	height: 30px;
	padding-left: 35px;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	&>button{
		height: 20px;
		border: none;
		margin-right: 30px;
		cursor: pointer;
		&:hover{
			background-color: #f0f0f0;
		}
	}
	&>p{
		margin-bottom: 0;
		font-weight: 500;
		margin-block: auto;
		opacity: .5;
		cursor: default;
	}
}

.novo-endereco{
	display: flex;
	margin-top: 10px;
	&>button{
		margin-left: 10px;
		border: 0;
		background-color: #fff;
		color: #002566;
		cursor: pointer;
	}
}

.metodo-pagamento{
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	width: 100%;
	height: fit-content;
	padding: 18px;
	&>div {
		display: flex;
		&>h3{
			font-size: 23px;
			font-weight: 500;
			color: var(--cor-primaria);
		}
	}
	&>h4 {
		padding-left: 30px;
		margin-top: 10px;
		margin-bottom: 0;
		width: 100%;
		text-align: left;
		font-size: 18px;
		font-weight: 500;
		color: #646464;
	}
}

.sumario-checkout{
	margin-left: auto;
}

.container-sumario{
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	width: 300px;
	height: fit-content;
	padding: 18px;
	margin-left: 20px;
}
.header-sumario{
	display: flex;
	&>h3{
		font-size: 18px;
		font-weight: 600;
	}
}

.body-sumario{
	&>.precos-pedido{
		height: 25px;
		display: flex;
		justify-content: space-between;
		&>p{
			font-size: 14px;
		}
		&>span{
			font-size: 14px;
			font-weight: 400;
		}
	}	
}

.modal-excluir-endereco{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-block: 20px;
	
	&>h5{
		font-size: 18px;
		font-weight: 500;
	}
}
.btns-cancelar-confirmar{
	display: flex;
	justify-content: center;
	align-items: center;
	&>.btn-sim {
		font-size: 14px;
		width: fit-content;
		height: 33px;
		padding-inline: 18px;
		border-radius: 7px;
		border: 1px solid #686868;
		background-color: var(--cor-primaria);
		color: #fff;
		font-weight: 500;
		&:disabled{
			background-color: var(--cor-primaria-disabled);
		}
	}

	&>.btn-nao{
		background-color: #999999;
		font-size: 14px;
		color: #fff;
		border: none;
		width: fit-content;
		height: 33px;
		padding-inline: 18px;
		margin-right: 10px;
		cursor: pointer;
		border-radius: 10px;
		&:hover{
			background-color: darken(#c0c0c0, 10);
		}
	}
}

.header-novo-endereco {
	height: 50px;
	background-color: #f1f1f1;
	border-bottom: 1px solid #c0c0c0;
	display: flex;
	align-items: center;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	
	&>h3{
		font-size: 18px;
		font-weight: 500;
		padding-left: 20px;
		margin-bottom: 0;
	}
}
.body-novo-endereco {
	background-color: #fff;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	padding: 20px;
}
.form-group{
	display: flex;
	flex-direction: column;
	// margin-block: 10px;
	&>label{
		text-align: left;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;
	}
	&>input{
		width: 100%;
		height: 32px;
		border: 1px solid #c0c0c0;
		border-radius: 5px;
		padding: 5px;
	}
}

// Estilo Tooltip cnpjcpf
.tooltip-message {
    position: absolute;
	font-size: 11px;
    background-color: var(--cor-primaria);
	color: #fff;
    border: 1px solid #ccc;
    padding-block: 5px;
    padding-inline: 6px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    z-index: 1000;
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

//ESTILO INPUTS
.grupo-form{
	display: flex;
    flex-direction: column;
    align-items: start;
	&>label{
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 0;

	}
}

.wrap-input-8 .input {
    display: flex;
    justify-content: center;
	width: 100%;
	height: 40px;
	font-size: 14px;
	box-sizing: border-box;
	letter-spacing: 1px;
	background-color: #f5f6fd;
	border: 0; 
	border-bottom: 2px solid var(--cor-primaria); 
	padding: 5px 10px 5px; 
	transition: 0.4s;
}
.wrap-input-8 .input:focus {
	outline: none;
}
.wrap-input-8 {
	width: 100%;
	margin-top: 5px;
	position: relative;
	& > i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 8px;
		&:hover{
			cursor: pointer;
		}
	}
}
.wrap-input-8 .input ~ .focus-border:before,
.wrap-input-8 .input ~ .focus-border:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: var(--cor-primaria);
	transition: 0.3s;
}
.wrap-input-8 .input ~ .focus-border:after {
	top: auto;
	bottom: 0;
	left: auto;
	right: 0;
}
.wrap-input-8 .input ~ .focus-border i:before,
.wrap-input-8 .input ~ .focus-border i:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 0;
	background-color: var(--cor-primaria);
	transition: 0.4s;
}
.wrap-input-8 .input ~ .focus-border i:after {
	left: auto;
	right: 0;
	top: auto;
	bottom: 0;
}
.wrap-input-8 .input:focus ~ .focus-border:before,
.wrap-input-8 .input:focus ~ .focus-border:after {
	width: 100%;
	transition: 0.3s;
}
.wrap-input-8 .input:focus ~ .focus-border i:before,
.wrap-input-8 .input:focus ~ .focus-border i:after {
	height: 100%;
	transition: 0.4s;
}

.select-perso{
	width: 100%;
	height: 40px;
	font-size: 14px;
	box-sizing: border-box;
	letter-spacing: 1px;
	background-color: #f5f6fd;
	border: 0; 
	border-bottom: 2px solid var(--cor-primaria); 
	padding: 5px 10px 5px; 
	transition: 0.4s;
}

// ESTILO TIME LINE
.time-line-checkout{
	margin-top: 20px;
	width: 100%;
	height: fit-content;
}
.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem
}

@media (min-width:768px) {
	.timeline-steps .timeline-step:not(:last-child):after {
		content: "";
		display: block;
		border-top: .25rem dotted #acacac;
		width: 7rem;
		position: absolute;
		left: 7.5rem;
		top: .3125rem;
		transition: background-color 1s ease-in-out;
	}
	.timeline-steps .timeline-step:not(:last-child).active:after {
		content: "";
		display: block;
		border-top: .25rem dotted var(--cor-primaria-hover);
		width: 7rem;
		position: absolute;
		left: 7.5rem;
		top: .3125rem;
		transition: background-color 1s ease-in-out;
	}
}

.timeline-steps .timeline-content {
    width: 10rem;
    text-align: center
}

.timeline-steps .timeline-content .inner-circle {
	border-radius: 1.5rem;
	height: 1rem;
	width: 1rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #898989;
	transition: background-color 1s ease-in-out;
	&.active{
		background-color: var(--cor-primaria);
	}
}

.timeline-steps .timeline-content .inner-circle:before {
	content: "";
	display: inline-block;
	height: 3rem;
	width: 3rem;
	min-width: 3rem;
	border-radius: 6.25rem;
	opacity: .5;
	background-color: #9b9b9b;
	transition: background-color 1s ease-in-out;
}

.timeline-steps .timeline-content .inner-circle.active:before {
	background-color: var(--cor-primaria-hover);
}

.container-infos-retirada{
	display: flex;
	flex-direction: column;
	align-items: start;
}

.card-instrucoes-retirada{
	display: flex;
	flex-direction: column;
	text-align: left;

	// width: 100%;
	margin-block: 15px;
	margin-inline: 30px;
	padding-block: 20px;
	padding-inline: 40px;
	height: fit-content;
	background-color: #ffef97;

	& > .card-instrucoes-header{
		& > h3 {
			font-size: 20px;
			font-weight: 700;
			font-family: 'Poppins', sans-serif;
			color: #e58c01;
		}
	}

	& > .card-instrucoes-body{

		& > p{
			font-size: 16px;
			font-weight: 500;
			color: #383838;
		}
	}
}


.container-endereco-horarios{
	width: 100%;
	padding-inline: 30px;
	display: flex;
	margin-block: 20px;
}

.endereco-retirada{
	width: 50%;

	& > .tit-end-retirada{
		display: flex;
		font-size: 30px;
		color: #858585;

		& > span {
			margin: 0;
			margin-left: 10px;
			font-size: 20px;
			font-weight: 600;
		}
	}
	
	& > .infos-end-retirada{
		display: flex;
		flex-direction: column;
		align-items: start;
		margin-top: 8px;
		font-size: 14px;
		& > span {
			font-weight: 700;
		}
		& > p {
			font-weight: 500;
			margin: 0;
		}
	}
}

.horario-retirada {
	width: 50%;
	
	& > .tit-hor-retirada {
		display: flex;
		font-size: 30px;
		color: #858585;
		
		& > span {
			margin: 0;
			margin-left: 10px;
			font-size: 20px;
			font-weight: 600;
		}
	}

	& > .infos-hor-retirada {
		display: flex;
		flex-direction: column;
		align-items: start;
		margin-top: 8px;
		font-size: 14px;
		& > span {
			font-weight: 700;
		}
		& > p {
			font-weight: 500;
			margin: 0;
		}

	}
}

</style>