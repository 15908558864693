<template>
	<div class="home">
		<!-- EXIBE O HEADER PARA DESKTOP -->
		<div class="d-none d-sm-none d-md-none d-lg-block">
			<HeaderDesktop></HeaderDesktop>
		</div>
	
		<!-- EXIBE O HEADER PARA TABLET -->
		<div class="d-none d-sm-none d-md-block d-lg-none">
			<HeaderTablet></HeaderTablet>
		</div>
	
		<!-- EXIBE O HEADER PARA MOBILE -->
		<div class="d-block d-sm-block d-md-none d-lg-none">
			<HeaderMobile></HeaderMobile>
		</div>
		<div v-if="isLoading" class="main-home" style="height: 400px; display: flex; align-items: center; justify-content: center;">
			<div class="spinner-border spinner-logout" style="width: 2rem; height: 2rem;" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<div v-else class="main-home">
			<div class="container">
				<div v="" id="carouselAnunciosHome" class="carousel slide" data-ride="carousel">
					<div class="carousel-inner">
						<div v-for="(banner, index) in lstBannersCarrossel" :class="['carousel-item', { active: index === 0 }]" :key="index" data-interval="2500">
							<img :src="configPaths.pathControle+banner.patharquivo+'?v='+Date.now()" class="d-block w-100" alt="...">
						</div>
					</div>
					<button class="carousel-control-prev ctrl-index" type="button" data-target="#carouselAnunciosHome" data-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="sr-only">Previous</span>
					</button>
					<button class="carousel-control-next ctrl-index" ref="click_btn_carrossel" type="button" data-target="#carouselAnunciosHome" data-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="sr-only">Next</span>
					</button>
				</div>
			</div>
			<div v-for="(destaque, i) in lstDestaquesProdutos" :key="i">
				<div v-if="destaque.produtos.length > 0 && destaque.ativo === 1" class="destaques-home">
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-12 titulo-destaques">
								<h2>{{ destaque.titulo }}</h2>
							</div>
						</div>
						<div class="carrossel-destaques">
							<CardProduto 
								v-for="(produto, indexProd) in destaque.produtos" 
								:key="indexProd" 
								:produtoData="produto"
								/>
						</div>
						<div :class="`custom-arrows-container-${i}`" style="position: relative;">
							<div :id="`arrow-left-${i}`" class="seta-esquerda">
								<i class="fa fa-chevron-left" aria-hidden="true"></i>
							</div>
							<div :id="`arrow-right-${i}`" class="seta-direita">
								<i class="fa fa-chevron-right" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>

</template>

<script>
// @ is an alias to /src
import HeaderDesktop from '@/components/Header/HeaderDesktop.vue';
import HeaderTablet from '@/components/Header/HeaderTablet.vue';
import HeaderMobile from '@/components/Header/HeaderMobile.vue';

import CardProduto from '@/components/CardProduto/CardProduto.vue';

import utils from '@/utilitarios/utils';

export default {
	name: 'HomeDestaques',
	components: {
		HeaderDesktop,
		HeaderTablet,
		HeaderMobile,
		CardProduto
	},
	data: function() {
        return {
			isLoading				: true,

			lstDestaquesProdutos	: [],
			lstBannersCarrossel		: [],
        };
    },
    methods: {
        carregaDestaquesProdutos(){
			let vself = this;
			this.isLoading = true;
            let parametros = [
                {parametro: 'op',           	valor: 'listagemdestaques'},
                {parametro: 'idclientesac',     valor: this.clienteSac.id},
                {parametro: 'idfilial',     	valor: this.filial.id},
				{parametro: 'idtabelaprecos',	valor: this.filial.idtabelaprecos},
				{parametro: 'pathimagens',		valor: this.configPaths.pathImgProdutos},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
            retorno.then(function (response) {
				response.data.forEach(destaque => {
					destaque.produtos.forEach(produto => {
						produto.imagem = vself.ordenaImagensProduto(produto.imagem)[0]
					});
				})
				vself.lstDestaquesProdutos = response.data;
				// console.log(vself.lstDestaquesProdutos);
				vself.isLoading = false;
				vself.$nextTick(() => {
					vself.lstDestaquesProdutos.forEach((destaque, index) => {
						$(`.destaques-home:eq(${index}) .carrossel-destaques`).slick({
							infinite: true,
							slidesToShow: 4,
							centerMode: destaque.produtos.length < 4 ? false : true,
							slidesToScroll: 1,
							autoplay: true,
							autoplaySpeed: 2000,
							// arrows: false,
							prevArrow: $(`#arrow-left-${index}`),
							nextArrow: $(`#arrow-right-${index}`),
							responsive: [
								{
								breakpoint: 1080,
								settings: {
									slidesToShow: 3,
									slidesToScroll: 1,
									infinite: true,
								}
								},
								{
								breakpoint: 900,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 1,
									infinite: true,
								}
								},
								{
								breakpoint: 768,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 1,
									infinite: true,
									centerMode: false,
									
								}
								},
							]
						});
					});
				});
            }).catch(function (response) {
                console.log(response.message);
				vself.isLoading = false;
            });
		},
		carregaBannersCarrossel(){
			let vself = this;
			this.isLoading = true;
			let parametros = [
                {parametro: 'op',           	valor: 'listagembannerscarrossel'},
                {parametro: 'idclientesac',     valor: this.clienteSac.id},
                {parametro: 'idfilial',     	valor: this.filial.id},
                {parametro: 'pathbanners',     	valor: '../ecommerce/arquivos/'+this.clienteSac.id+'/'+this.filial.id+'/bannerscarrossel'},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
            retorno.then(function (response) {
				vself.lstBannersCarrossel = vself.preparaSrcsCarrossel(vself.ordenaBannersCarrossel(response.data.arquivos));
				vself.isLoading = false;
				setTimeout(() => {
					vself.$refs.click_btn_carrossel.click()
				}, 2500);
            }).catch(function (response) {
                console.log(response.message);
				vself.isLoading = false;
            });
		},
		ordenaBannersCarrossel(lstbanners){
                let lstordenada = lstbanners.sort((a, b) => {
                    if (a.arquivo < b.arquivo) {
                        return -1;
                    }
                    if (a.arquivo > b.arquivo) {
                        return 1;
                    }
                    return 0;
                });
                return lstordenada;
		},
		ordenaImagensProduto(lstimagens){
			let lstordenada = lstimagens.sort((a, b) => {
				if (a.arquivo < b.arquivo) {
					return -1;
				}
				if (a.arquivo > b.arquivo) {
					return 1;
				}
				return 0;
			});
			return lstordenada;
		},
		preparaSrcsCarrossel(lstbanners){
			lstbanners.forEach(banner => {
				banner.patharquivo = banner.patharquivo.replace('../', '');
			});
			return lstbanners;
		},
		toCapitalized(value){
			return utils.capitalizeText(value);
		},
		preparaDescricaoRouter(descricao){
			return utils.prepDescricaoRouter(descricao);
		},
    },
	computed: {
		clienteSac(){
			return this.$store.state.clienteSac;
		},
		filial(){
			return this.$store.state.filial;
		},
		destaquesProdutos(){
			return this.$store.state.destaquesProdutos;
		},
		usuarioLogado() {
			return this.$store.state.usuarioLogado;
		},
		configPaths(){
			return this.$store.state.configPaths;
		},
		produtosAleatorios(){
			return this.$store.state.produtosAleatorios;
		}
	},
	mounted: function() {
		// console.log(window.localStorage.getItem('usuarioLogado'));
		// console.log(this.$store.state.usuarioLogado);
		
		this.carregaBannersCarrossel()
		this.carregaDestaquesProdutos()
		// console.log(this.usuarioLogado)
	}
}
</script>
<style scoped lang="scss">

.pc-container{
	margin-inline: 5px;
}

.home {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.066)
}
.main-home {
	width: 100%;
	min-height: 800px;
	margin-top: 15px;
}
.destaques-home {
	width: 1200px;
	margin-inline: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(255,255,255);
	margin-top: 15px;
	// margin-bottom: 23px;
	padding-inline: 10px;
	padding-bottom: 23px;
	border-radius: 10px;
	overflow-x: hidden;
	// box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.766);
	@media (max-width: 575px) { // RESPONSIVO MOBILE
		padding-inline: 0;
	}
	@media (max-width: 1250px) { // RESPONSIVO TABLET
		width: 95%;
	}
}

.titulo-destaques{
	display: flex;
	height: 50px;
	align-items: center;
	// margin-bottom: 8px;
	& > h2 {
		color: var(--cor-primaria);
		font-size: 1.4rem;
		font-weight: 600;
		margin-bottom: 0;
	}
}

.carrossel-destaques{
	width: 100%;
	margin-inline: auto;
	mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 2%, rgb(255, 255, 255) 10%, rgb(255, 255, 255) 52%, rgb(255, 255, 255) 90%, rgba(255, 255, 255, 0) 98%);
	@media (max-width: 768px) { // RESPONSIVO MOBILE
		mask-image: none;
	}
}

.card-destaques{
	min-height: 300px;
	width: auto !important;
	margin-inline: 5px !important;
	margin-bottom: 5px !important;
	border: 2px #bcbcbc solid;

	@media (max-width: 1250px) { // RESPONSIVO TABLET
		height: 330px;
	}
}

.header-card {
	display: flex;
	justify-content: center;
	align-items: center;
	& > img {
		height: 160px;
		padding: 10px;
	}
}
.footer-card {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding-bottom: 8px;
	padding-inline: 15px;
	& > h6 {
		font-size: 1.1rem;
		display: -webkit-box;
		line-clamp: 3;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0;
		margin-bottom: 5px;
	}
	& > small {
		margin-bottom: 5px;
		color: rgb(106, 106, 106);
		text-decoration: line-through;
	}
	& > span {
		color: var(--cor-primaria);
		font-weight: 600;
		font-size: 20px
	}
	& > p {
		min-height: 25px;
		font-size: 13px;
		min-width: 50px;
		margin: 0;
		text-align: left;
	}
	& > .msg-produto-indisponivel{
		text-align: center;
	}
}
@media (max-width: 575px) { // RESPONSIVIDADE MOBILE
	.card-rspsv{
		padding-inline: 5px !important;
	}
}

.ctrl-index { // CORREÇÃO DO ZINDEX DO CONTROLE DO CARROSSEL DO BOOTTRAP
	z-index: 0 !important;
}
</style>